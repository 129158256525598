import { makeStyles } from '@mui/styles';
import { COLOR_PINK } from 'styles/variables/colors';

export const useStyles = makeStyles((theme) => ({
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    padding: theme.spacing(0, 2),

    [theme.breakpoints.down('md')]: {
      overflowY: 'scroll',
    },

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      padding: theme.spacing(5, 2),
    },
  },
  ctaModal: {
    zIndex: 99,
    background: '#fff',
    borderRadius: '16px',
    fontFamily: 'Lato !important',
    position: 'relative',
  },
  modalHeadline: {
    fontSize: 20,
    fontWeight: 700,
    lineHeight: '24px',
    width: '100%',
    color: '#616161',

    '& span': {
      color: '#7833FF',
    },
  },
  modalHeadlineTemplate: {
    color: `${COLOR_PINK} !important`,
  },
  closeIcon: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
  },
  line: {
    opacity: 0.5,
    borderTop: '1px solid #616161',
    margin: '16px 0',
  },
  emailForm: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));
