import Image from 'next/image';
import PropTypes from 'prop-types';
import EmailFormVariant from 'components/EmailFormVariant';
import { Box, Grid, Typography } from '@mui/material';
import { useStyles } from './styles';
import { useModal } from 'contexts/EmailFormModalContext';
import { LandingPageContextEnum } from 'enums';
import { COLOR_PURPLE } from 'styles/variables/colors';

const Props = {
  context: PropTypes.string,
};

export default function CtaModalEmailForm({ context }) {
  const classes = useStyles();
  const templatesPageContext = context === 'templateLoginFlow';
  const { isModalOpen, setIsModalOpen } = useModal();

  const closeModal = () => {
    setIsModalOpen(false);
  };

  if (!isModalOpen) return null;

  return (
    <Box className={classes.modalOverlay} onClick={closeModal}>
      <Box
        px={3}
        py={4}
        maxWidth={515}
        className={classes.ctaModal}
        onClick={(e) => e.stopPropagation()}
      >
        <Box className={classes.closeIcon}>
          <Image
            src="/images/home-page/close-icon.svg"
            onClick={closeModal}
            alt="Close Modal"
            width={24}
            height={24}
            loading="lazy"
          />
        </Box>

        <Box mb={1}>
          <Typography className={classes.modalHeadline}>
            You're one step closer of the{' '}
            <span className={templatesPageContext && classes.modalHeadlineTemplate}>
              world's largest collection
            </span>{' '}
            of scientifically accurate illustrations!
          </Typography>
        </Box>

        <EmailFormVariant
          buttonColor={!templatesPageContext ? COLOR_PURPLE : 'pink'}
          textButtonLogged="Start Creating Now"
          context={LandingPageContextEnum.HOME_PAGE}
          templatesPage={templatesPageContext}
        />

        <Box display="flex" justifyContent="center" mt={2}>
          <Box mr={0.8}>
            <Typography variant="body1" component="p" style={{ fontSize: '18px' }}>
              No credit card required
            </Typography>
          </Box>
        </Box>

        <Box px={2} py={2} mt={3} bgcolor="#eee" borderRadius="4px">
          <Box display="flex" alignItems="center" justifyContent="center">
            <Box>
              <Image
                src="/images/home-page/feedback.webp"
                alt="feedback image"
                loading="lazy"
                width={48}
                height={48}
              />
            </Box>

            <Box ml={1}>
              <Box>
                <Typography
                  variant="body1"
                  component="p"
                  style={{ fontSize: '16px', fontFamily: 'Lato' }}
                >
                  <i>No other tool does what Mind the Graph does!</i>
                </Typography>
              </Box>

              <Box>
                <Typography
                  variant="body1"
                  component="p"
                  style={{ fontSize: '14px', fontFamily: 'Lato' }}
                >
                  <b>Margarida Agrochao</b>
                  <br />
                  PhD student at Caltech / USA
                </Typography>
              </Box>
            </Box>
          </Box>

          <hr className={classes.line} />

          <Box color="#616161">
            <Typography component="p" style={{ fontSize: '18px', fontFamily: 'Lato' }}>
              Trusted by researchers and academics from 100+ institutions
            </Typography>

            <Grid container alignItems="center">
              <Grid justifyContent="space-between" container>
                <Box width={137} display="flex" justifyContent="center" alignItems="center" mt={2}>
                  <Image
                    src="/images/stanford-logo.webp"
                    alt="stanford logo"
                    loading="lazy"
                    width={66}
                    height={30}
                  />
                </Box>

                <Box width={137} display="flex" justifyContent="center" alignItems="center" mt={2}>
                  <Image
                    src="/images/ucla-logo.webp"
                    alt="ucla logo"
                    loading="lazy"
                    width={55}
                    height={19}
                  />
                </Box>

                <Box width={137} display="flex" justifyContent="center" alignItems="center" mt={2}>
                  <Image
                    src="/images/pearson-logo.webp"
                    alt="pearson logo"
                    loading="lazy"
                    width={55}
                    height={39}
                  />
                </Box>
              </Grid>

              <Grid justifyContent="space-between" container>
                <Box width={137} display="flex" justifyContent="center" alignItems="center" mt={3}>
                  <Image
                    src="/images/harvard-logo.webp"
                    alt="harvard logo"
                    loading="lazy"
                    width={100}
                    height={27}
                  />
                </Box>

                <Box width={137} display="flex" justifyContent="center" alignItems="center" mt={3}>
                  <Image
                    src="/images/abbott-logo.webp"
                    alt="abbot logo"
                    loading="lazy"
                    width={67}
                    height={18}
                  />
                </Box>

                <Box width={137} display="flex" justifyContent="center" alignItems="center" mt={3}>
                  <Image
                    src="/images/mcgill-logo.webp"
                    alt="mcgill logo"
                    loading="lazy"
                    width={87}
                    height={21}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CtaModalEmailForm.propTypes = Props;
